nav {
    height: 64px;
    background-color: #2C4596;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0px 15px 0px 0px;
}

.logo {
    height: 40px;
}

.sidebar {
    height: 100vh;
    width: 280px;
    min-width: 280px;
    min-height: 100vh;
    position: absolute;
    z-index: 999;
    box-shadow: rgb(0 0 0 / 13%) 0px 0px 10px;
    background-color: white;
}

.sidebar.collapsed {
    padding-top: 15px;
    width: auto;
    min-width: auto;
}

.content {
    position: relative;
}

.sidebar.collapsed {
    position: fixed;
    left: -280px;
}

@media screen and (max-width: 992px) {
    
}