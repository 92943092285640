body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-large-text {
  font-size: 20px;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

.quote-info {
  list-style: outside none none;
  width: 100%;
  padding: 12px;
}

.quote-info-item {
  display: flex;
  align-items: center;
  text-align: left; 
}

.quote-info-item svg {
  margin-right: 10px;
  min-width: 16px;
}

.border-bottom {
  border-bottom: 1px solid #f1f2f6;
  padding-bottom: 18px;
  margin-bottom: 18px;
}

.text-center {
  text-align: center;
}

.text-success {
  color: #20c997;
}

.text-gray {
  color: #737a9e;
}

.text-danger {
  color: #ff4d4f !important;
}

.text-info {
  color: #2c99ff;
}

.orange-text {
  color: #E9682A;
}

.promo-code-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.promo-code-item {
  display: flex;
  align-items: center;
}

.ant-page-header-heading-title {
  text-wrap: wrap !important;
}

.ant-row {
  margin: 0px;
}

.ant-space-item:first-of-type {
  width: 100%;
}

@media only screen and (max-width: 440px) {
  .ant-space-item:first-of-type {
      max-width: 250px;
  }
}

.ant-input-prefix {
   padding-left: 8px !important;
}

.ant-segmented-item-selected .ant-segmented-item-label {
  background-color: #f37920;
  color: #fff !important;
  border-radius: 5px;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-segmented-item {
  width: 50%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-segmented-thumb {
  background-color: #f37920 !important;
  color: #fff !important;
  border-radius: 5px;
}

.ant-select-item-option-content {
  white-space: wrap !important;
}

.ant-select-selection-search-input {
  height: 48px !important;
  padding: 0px 10px !important;
}

.pdf-loader-icon.loading {
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
